import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  connectAuthEmulator,
} from 'firebase/auth';
import {
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getFirestore,
  initializeFirestore,
} from 'firebase/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions';
import {
  connectStorageEmulator,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';

const config = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
};

class Firebase {
  // initialize Firebase
  constructor() {
    this.location = window?.location?.hostname ? window.location.hostname : '';

    this.app = initializeApp(config);
    this.auth = getAuth(this.app);
    this.storage = getStorage(this.app);

    if (this.location === 'localhost') {
      // Prevents testing firebase users from showing up in the Firebase console
      // Be sure to run firebase emulators:start to see the outputting
      // firestore data
      this.db = initializeFirestore(this.app, {
        host: 'localhost:8082',
        ssl: false,
      });
      connectFirestoreEmulator(this.db, 'localhost', 8082);
    } else {
      this.db = getFirestore(this.app);
    }
    this.functions = getFunctions(this.app);

    if (this.location === 'localhost') {
      connectStorageEmulator(this.storage, 'localhost', 9199);
      // For testing cloud functions locally
      // using cli command firebase emulators:start
      connectFunctionsEmulator(this.functions, 'localhost', 5002);
      //connect to auth emu locally
      connectAuthEmulator(this.auth, 'http://127.0.0.1:9099', {
        disableWarnings: true,
      });
    }

    /* Helpers */

    this.addNetsuiteContactRecord = httpsCallable(
      this.functions,
      'addNetsuiteContactRecordProd',
    );

    this.addNetsuiteQuoteRecord = httpsCallable(
      this.functions,
      'addNetsuiteQuoteRecordProd',
    );

    this.subscribeKlaviyoNewsletter = httpsCallable(
      this.functions,
      'subscribeKlaviyoNewsletter',
    );

    this.sendMailOnTradeDocUpload = httpsCallable(
      this.functions,
      'sendMailOnTradeDocUpload',
    );

    this.sendMailOnTradeSignUp = httpsCallable(
      this.functions,
      'sendMailOnTradeSignUp',
    );

    this.sendMailOnUserCreationFailure = httpsCallable(
      this.functions,
      'sendMailOnUserCreationFailure',
    );

    this.sendVerficationCodeToMail = httpsCallable(
      this.functions,
      'sendVerficationCodeToMail',
    );

    this.verifyEmailCode = httpsCallable(this.functions, 'verifyEmailCode');
  }

  // *** Setup Class properties with Firebase Storage API ***
  firebaseUploadTradeDocsFile = async (file, name) => {
    const tradeDocRef = ref(this.storage, 'trade-documents');
    const tradeDocNameRef = ref(tradeDocRef, name);

    await uploadBytes(tradeDocNameRef, file);

    return await getDownloadURL(tradeDocNameRef);
  };

  // *** Setup Class properties with Firebase Auth API ***

  firebaseCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(this.auth, email, password);

  firebaseSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(this.auth, email, password).then(
      (signedInUser) => {
        localStorage.removeItem('previousAccountRoute');
        return signedInUser.user;
      },
    );

  firebaseSignOut = () =>
    signOut(this.auth).then(() =>
      localStorage.removeItem('previousAccountRoute'),
    );

  firebasePasswordReset = (email) => sendPasswordResetEmail(this.auth, email);

  firebaseSendEmailVerification = () => {
    console.log(this.auth.currentUser);
    return sendEmailVerification(this.auth.currentUser, {
      url: process.env.GATSBY_FIREBASE_CONFIRMATION_EMAIL_REDIRECT,
    });
  };

  firebaseDeleteUser = () => this.auth.currentUser.delete();

  firebaseAddNetsuiteContactRecord = (data) =>
    this.addNetsuiteContactRecord(data)
      .then((result) => {
        // Read result of the Cloud Function.
        console.log(
          'Contact record successfully added to user document and netsuite',
          result,
        );
      })
      .catch((error) => {
        // Getting the Error details.
        console.log('Contact record error:', error.code);
      });

  // *** Setup Class properties with Firebase Firestore User API ***

  user = (uid) => doc(this.db, `users/${uid}`);

  users = () => collection(this.db, 'users');

  /**
   * Method which uses Firebases onAuthStateChanged
   * event listener, this listens for changes to the user's sign-in state
   * and merges the Firebase Auth user with the Firestore user
   * onAuthUserListener is being called within the withAuthentication HOC
   * and gets triggered on sign in and on sign out
   * @param {function} onSuccess callback if user is defined
   * @param {function} fallback if user is not defined
   */
  // onAuthStateChanged adds an observer for changes to the user's sign-in state.
  onAuthUserListener = (onSuccess, fallback) =>
    onAuthStateChanged(this.auth, (authUser) => {
      //// console.log(authUser);
      if (authUser) {
        if (authUser.emailVerified) {
          getDoc(this.user(authUser.uid))
            // .get()
            .then((doc) => {
              const dbUser = doc.data();
              // merge auth and db user
              const mergedAuthUserWithDb = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbUser,
              };
              onSuccess(mergedAuthUserWithDb);
              // ns_contact_record gets set within the addNetsuiteContactRecord
              // firebase cloud function
              // if the contact record has already been set do not call the firebaseAddNetsuiteContactRecord
              // cloud function
              if (!doc.data()?.ns_contact_record_sent) {
                this.firebaseAddNetsuiteContactRecord({
                  ...doc.data(),
                  uid: authUser.uid,
                });
              }
            });
        } else if (
          !['/account/create', '/account/login', '/trade'].includes(
            location.pathname,
          )
        ) {
          this.firebaseSignOut();
        }
      } else {
        fallback();
      }
    });
}

export default Firebase;
